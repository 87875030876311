import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import getConfig from 'next/config'

import { Button } from '@thg-commerce/gravity-elements'
import { ButtonEmphases } from '@thg-commerce/gravity-elements/Button/Button'

import { Logout as LOGOUT_MUTATION } from '../../graphql/Mutation/Logout.graphql'
import { useSiteDefinition } from '../ConfigurationLoader'
import { i18n } from '../i18n'
import { Routes } from '../Routing/Routes'

export interface LogoutProps {
  emphasis?: ButtonEmphases
  className?: string
  icon?: React.ReactNode
}

const config = getConfig()
const { ENTERPRISE_PREFIX } = config.publicRuntimeConfig

export const Logout = ({ icon, className, emphasis }: LogoutProps) => {
  const { brand, subsite } = useSiteDefinition()

  const [logout] = useMutation(LOGOUT_MUTATION)

  const logoutHandler = async () => {
    const body = await fetch(`${ENTERPRISE_PREFIX}${Routes.Logout}`, {
      method: 'POST',
      redirect: 'manual',
      headers: {
        'x-brand': brand,
        'x-subsite': subsite,
      },
    })

    await logout()
    window.location.href = body.url
  }

  return (
    <Button
      className={className}
      onClick={logoutHandler}
      aria-label={i18n('account.logout.button.text')}
      data-testid="button-logout"
      emphasis={emphasis || 'low'}
    >
      {icon && icon}
      {i18n('account.logout.button.text')}
    </Button>
  )
}
