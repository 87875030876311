import { Property } from 'csstype'

import {
  TextEntry,
  TextStyle,
  theme as BaseTheme,
} from '@thg-commerce/gravity-theme'

export interface TabsPanelInterface {
  focus?: {
    border: {
      color: Property.Color
    }
  }
}

export interface TabsInterface {
  inactive: {
    border: {
      color: Property.Color
      width: string
      bottom: {
        color: Property.Color
        width: string
      }
      left: {
        color: Property.Color
      }
    }
    textStyling: {
      entry: TextEntry
      style: TextStyle
    }
    textColor: {
      darker: Property.Color
      light: Property.Color
    }
    backgroundColor: Property.Color
    fontWeight: string
  }
  active: {
    border: {
      color: Property.Color
      bottom: {
        color?: Property.Color
        width: string
      }
    }
    backgroundColor: Property.Color
    fontWeight: string
    textColor: Property.Color
  }
  width: {
    lg: string
    sm: string
  }
  height: string
  tabList: {
    border: {
      bottom: {
        color?: Property.Color
        width: string
      }
    }
  }
}

export const theme: TabsInterface = {
  inactive: {
    border: {
      color: BaseTheme.colors.palette.greys.darker,
      width: '1px',
      bottom: {
        color: 'transparent',
        width: '1px',
      },
      left: {
        color: 'transparent',
      },
    },
    fontWeight: 'normal',
    textStyling: {
      entry: 'bodyText',
      style: 'default',
    },
    textColor: {
      darker: BaseTheme.colors.palette.brand.base,
      light: BaseTheme.colors.palette.greys.dark,
    },
    backgroundColor: BaseTheme.colors.palette.greys.light,
  },
  active: {
    border: {
      color: 'transparent',
      bottom: {
        color: BaseTheme.colors.palette.greys.darker,
        width: '3px',
      },
    },
    backgroundColor: '#ffffff',
    fontWeight: '700',
    textColor: '',
  },
  width: {
    lg: '125px',
    sm: '145px',
  },
  height: '48px',
  tabList: {
    border: {
      bottom: {
        color: 'transparent',
        width: '2px',
      },
    },
  },
}
