import { Features as FEATURES_QUERY } from '../../../graphql/Query/Account/Features.graphql'
import { SUPPORTED_FEATURES } from '../../data'
import { ResolverProvider } from '../types'

const hasFeature = (features: any, identifier: string): boolean => {
  return (
    Object.keys(features).filter((key) => features[key] === identifier).length >
    0
  )
}

export const Features: ResolverProvider = (
  client,
  { behaviours },
  { siteConfig },
) => async (_, args: { input: { identifiers: string[] } }, context) => {
  if (behaviours.override) {
    const AVAILABLE_FEATURES = await import('../../data/Features').then(
      (mod) => {
        return mod.AVAILABLE_FEATURES
      },
    )
    const requestedFeatures = args.input.identifiers.filter((feature) =>
      hasFeature(AVAILABLE_FEATURES, feature),
    )

    const featureAvailableInConfig = requestedFeatures.filter((feature) => {
      return siteConfig.hasOwnProperty(feature) && siteConfig[feature]
    })

    return {
      enabled: Array.from(
        new Set(
          featureAvailableInConfig.concat(
            Object.keys(requestedFeatures).map(
              (feature) => requestedFeatures[feature],
            ),
          ),
        ),
      ),
      __typename: 'Features',
    }
  }

  const { data } = await client.query({
    context,
    variables: args,
    query: FEATURES_QUERY,
  })

  const requestedFeatures = args.input.identifiers.filter((feature) =>
    hasFeature(SUPPORTED_FEATURES, feature),
  )

  const featureAvailableInConfig = requestedFeatures.filter(
    (feature) => siteConfig.hasOwnProperty(feature) && siteConfig[feature],
  )

  return {
    enabled: Array.from(
      new Set(featureAvailableInConfig.concat(data['features'])),
    ),
    __typename: 'Features',
  }
}
