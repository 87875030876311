import { BreakpointArray } from '@thg-commerce/gravity-theme'
import { Margin } from '@thg-commerce/gravity-theme/margin'

import { CarouselButtonPlacement } from './types'

export type CarouselIndicatorStyle = 'letterbox' | 'hidden' | 'dot'
export type ChevronIconStyle = {
  chevronIcon: {
    viewBox: string
    width: string
    height: string
    right: {
      svgPath: string
    }
    left: {
      svgPath: string
    }
  }
}

export interface CarouselThemeInterface {
  chevronIconStyle: ChevronIconStyle
  buttonPlacement?: CarouselButtonPlacement
  alternativeStyle?: boolean
  fullWidthCarousel?: boolean
  controls?: {
    visible?: BreakpointArray<boolean>
    size?: number
  }
  pageIndicator?: {
    style: CarouselIndicatorStyle
    dotHorizontalMargin?: number
    wrapper: {
      margin: Margin
    }
  }
}

export const theme: CarouselThemeInterface = {
  pageIndicator: {
    dotHorizontalMargin: 1,
    style: 'hidden',
    wrapper: {
      margin: {
        top: 3,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
  },
  chevronIconStyle: {
    chevronIcon: {
      viewBox: '0 0 24 24',
      width: '24px',
      height: '24px',
      right: {
        svgPath: '',
      },
      left: {
        svgPath: '',
      },
    },
  },
}
